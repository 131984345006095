import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from 'components/layout/SelectLanguage/hooks';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';

import { StyledToastContainer } from 'components/core/Toast';

import { ThemeProvider } from 'styled-components';
import Routes from 'config/routes';

import useApolloClient from 'services/apollo/hooks/useApolloClient';
import ExitModal from 'components/layout/ExitModal';
import { UserProvider } from 'services/cognito/user';

function ApolloEnvironment() {
  const apolloClient = useApolloClient();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('View 4.0.0-1543075951');
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <Routes />
        <StyledToastContainer />
        <ExitModal />
      </HelmetProvider>
    </ApolloProvider>
  );
}

function App() {
  const theme = useSelector((state) => state.theme);

  function checkForTokens() {
    const accessToken = window.sessionStorage.getItem('accessToken');
    const refreshToken = window.sessionStorage.getItem('refreshToken');
    if (!accessToken || !refreshToken) {
      if (window.localStorage.length > 0) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
      }
    }
  }

  checkForTokens();
  useLanguage();

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <UserProvider>
          <ApolloEnvironment />
        </UserProvider>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;
